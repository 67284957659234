import { Info } from '@phosphor-icons/react';
import { ComponentProps, FC } from 'react';
import { PlanCardListItem, useOfferContext } from '@updater/ui-widgets';
import { TvInternetOffer } from '@updater/consumer-graph';
import {
  extractTvPlanNameFromBundle,
  useTvAllInPrice,
} from './useTvAllInPrice';

/*
 ** Meant to show "All-In" price for TV service for FCC compliance
 ** FCC #: FCC-24-29
 */
export const PlanCardTvAllInPriceCompliance: FC<
  Partial<ComponentProps<typeof PlanCardListItem>>
> = (props) => {
  const { name, products, providerCode } = useOfferContext() as TvInternetOffer;
  const hasInternet = products?.internet;
  const hasTv = products?.tv;
  const hasPhone = products?.phone;
  const hasOnlyTv = !hasInternet && !hasPhone && hasTv;
  const tvPlanName = extractTvPlanNameFromBundle(name);
  const tvAllInPrice = useTvAllInPrice(tvPlanName);

  if (
    providerCode !== 'spectrum' ||
    !hasTv ||
    !tvPlanName ||
    !tvAllInPrice.discountAmount
  ) {
    return null;
  }

  const displayText = hasOnlyTv
    ? `$${tvAllInPrice.discountAmount}/mo off reg. rate of $${tvAllInPrice.longTermPrice}/mo`
    : `Includes ${tvPlanName} at $${tvAllInPrice.shortTermPrice}/mo (reg. $${tvAllInPrice.longTermPrice}/mo)`;

  return (
    <PlanCardListItem
      icon={<Info weight="light" size={22} />}
      text={displayText}
      {...props}
    />
  );
};
