import { Box } from '@updater/ui-uds';
import {
  PlanCardPlanInfo,
  PlanCardList,
  PlanCardPricingDescription,
  PlanCardListItemTelevision,
  PlanCardListItemDiscount,
  PlanCardListItemContractLength,
  PlanCardListItemFiberConnection,
  PlanCardProviderInfo,
  PlanCardProductInfo,
  PlanCardOfferInfo,
  PlanCardListItemDummy,
  useOfferContext,
  PlanCardDummyOfferDisclaimer,
  PlanCardBroadbandLinkoutItem,
} from '@updater/ui-widgets';
import { PlanCardTvAllInPriceCompliance } from './PlanCardTvAllInPriceCompliance';
import { ComponentProps, FC } from 'react';
import { TvInternetOffer } from '@updater/consumer-graph';

type PlanSummaryProps = ComponentProps<typeof Box>;

export const PlanSummary: FC<PlanSummaryProps> = ({ ...rest }) => {
  const offer = useOfferContext() as TvInternetOffer;
  const isBasicDummyOffer = Boolean(
    offer?.metadata?.dummy && offer?.metadata?.dummyOfferType === 'basic'
  );
  return (
    <Box {...rest}>
      <PlanCardProviderInfo />
      {isBasicDummyOffer ? (
        <>
          <PlanCardProductInfo />
          <PlanCardList>
            <PlanCardListItemDummy />
            <PlanCardDummyOfferDisclaimer />
          </PlanCardList>
        </>
      ) : (
        <>
          <PlanCardProductInfo />
          <PlanCardPlanInfo />
          <PlanCardList>
            <PlanCardTvAllInPriceCompliance />
            <PlanCardPricingDescription />
            <PlanCardListItemTelevision />
            <PlanCardListItemDiscount />
            <PlanCardDummyOfferDisclaimer />
            <PlanCardListItemContractLength />
            <PlanCardListItemFiberConnection />
            <PlanCardBroadbandLinkoutItem />
          </PlanCardList>
          <PlanCardOfferInfo />
        </>
      )}
    </Box>
  );
};
