import { ProviderConfig, ProviderTypes } from 'types';
import { XfinityTheme } from 'config/themes';

export const Comcast: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'xfinity',
  dcCode: 'Comcast',
  name: 'Xfinity',
  displayName: 'Xfinity',
  reskinTheme: XfinityTheme,
  reskinTitle: 'Xfinity Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'linkout_url',
  showDisclaimerTab: true,
};
