type tvAllInPrice = {
  discountAmount: number;
  longTermPrice: number;
  shortTermPrice: number;
};

export const useTvAllInPrice = (planName: string): tvAllInPrice => {
  const allInPrice: tvAllInPrice = {
    discountAmount: 0,
    longTermPrice: 0,
    shortTermPrice: 0,
  };

  // TODO pull from offers call with fallback to these prices
  switch (planName) {
    case 'TV Select Signature':
    case 'TV Signature':
      allInPrice.longTermPrice = 115.0;
      allInPrice.shortTermPrice = 95.0;
      break;
    case 'TV Select Plus':
      allInPrice.longTermPrice = 125.0;
      allInPrice.shortTermPrice = 105.0;
      break;
    case 'TV Mi Plan Latino':
      allInPrice.longTermPrice = 90.0;
      allInPrice.shortTermPrice = 70.0;
      break;
    default:
      // handled by initialization of allInPrice
      break;
  }

  allInPrice.discountAmount =
    allInPrice.longTermPrice - allInPrice.shortTermPrice;

  return allInPrice;
};

export const extractTvPlanNameFromBundle = (bundleName: string): string => {
  const match = bundleName?.match(/TV[^+]+/);
  return match ? match[0].trim() : '';
};
